var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"addTable"},[_c('div',{staticClass:"table-title"},[_vm._v("销售规格")]),_c('div',{staticClass:"table-top"},[_c('div',{staticClass:"describe"},[_vm._v("在标题栏中输入或选择内容可以进行筛选和批量填充")]),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.handleBatchAdd}},[_vm._v(" 批量填充 ")])],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableList,"border":"","span-method":_vm.objectSpanMethod}},[_c('el-table-column',{attrs:{"prop":"skuSize","label":"大小","width":"150"}}),_c('el-table-column',{attrs:{"prop":"skuName","label":"规格"}}),_c('el-table-column',{attrs:{"prop":"retailPrice","label":"价格(元)"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"mytable-header"},[_c('span',[_vm._v("价格")]),_c('span',[_c('el-input',{attrs:{"placeholder":"价格(元)","size":"mini"},model:{value:(_vm.$store.state.goods.tableForm.retailPrice),callback:function ($$v) {_vm.$set(_vm.$store.state.goods.tableForm, "retailPrice", $$v)},expression:"$store.state.goods.tableForm.retailPrice"}})],1)])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"价格","size":"mini"},model:{value:(row.retailPrice),callback:function ($$v) {_vm.$set(row, "retailPrice", $$v)},expression:"row.retailPrice"}})]}}])}),_c('el-table-column',{attrs:{"prop":"inventory","label":"库存(件)"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"mytable-header"},[_c('span',[_vm._v("库存")]),_c('span',[_c('el-input',{attrs:{"placeholder":"库存(件)","size":"mini"},model:{value:(_vm.$store.state.goods.tableForm.inventory),callback:function ($$v) {_vm.$set(_vm.$store.state.goods.tableForm, "inventory", $$v)},expression:"$store.state.goods.tableForm.inventory"}})],1)])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"库存","size":"mini"},model:{value:(row.inventory),callback:function ($$v) {_vm.$set(row, "inventory", $$v)},expression:"row.inventory"}})]}}])}),_c('el-table-column',{attrs:{"prop":"costPrice","label":"出厂价(元)"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"mytable-header"},[_c('span',[_vm._v("出厂价")]),_c('span',[_c('el-input',{attrs:{"placeholder":"出厂价(元)","size":"mini"},model:{value:(_vm.$store.state.goods.tableForm.costPrice),callback:function ($$v) {_vm.$set(_vm.$store.state.goods.tableForm, "costPrice", $$v)},expression:"$store.state.goods.tableForm.costPrice"}})],1)])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"出厂价","size":"mini"},model:{value:(row.costPrice),callback:function ($$v) {_vm.$set(row, "costPrice", $$v)},expression:"row.costPrice"}})]}}])}),(_vm.$store.state.goods.isPresale)?_c('el-table-column',{attrs:{"prop":"presalePrice","label":"预售价"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"mytable-header"},[_c('span',[_vm._v("预售价")]),_c('span',[_c('el-input',{attrs:{"placeholder":"预售价(元)","size":"mini"},model:{value:(_vm.$store.state.goods.tableForm.presalePrice),callback:function ($$v) {_vm.$set(_vm.$store.state.goods.tableForm, "presalePrice", $$v)},expression:"$store.state.goods.tableForm.presalePrice"}})],1)])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"预售价","size":"mini"},model:{value:(row.presalePrice),callback:function ($$v) {_vm.$set(row, "presalePrice", $$v)},expression:"row.presalePrice"}})]}}],null,false,3769934169)}):_vm._e(),(_vm.$store.state.goods.isBatch)?_c('el-table-column',{attrs:{"prop":"presalePrice","label":"预售价"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"mytable-header"},[_c('span',[_vm._v("批发价")]),_c('span',[_c('el-input',{attrs:{"placeholder":"批发价(元)","size":"mini"},model:{value:(_vm.$store.state.goods.tableForm.batchPrice),callback:function ($$v) {_vm.$set(_vm.$store.state.goods.tableForm, "batchPrice", $$v)},expression:"$store.state.goods.tableForm.batchPrice"}})],1)])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"批发价","size":"mini"},model:{value:(row.batchPrice),callback:function ($$v) {_vm.$set(row, "batchPrice", $$v)},expression:"row.batchPrice"}})]}}],null,false,3879024635)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"skuPicture","label":"上传规格图片"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('uploadBtn',{attrs:{"index":row.index,"rows":row},model:{value:(row.skuPicture),callback:function ($$v) {_vm.$set(row, "skuPicture", $$v)},expression:"row.skuPicture"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }