<template>
  <div>
    <!-- label-position="top" -->
    <el-form
      :model="formData"
      ref="form"
      label-width="100px"
      width="500px"
      size="mini"
      v-loading="loading"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item prop="itemName" label="商品名称" verify>
            <el-input v-model="formData.itemName"></el-input>
          </el-form-item>
          <el-form-item prop="firstCategory" label="商品类目" verify>
            <selectCascader
              :one.sync="formData.firstCategory"
              :two.sync="formData.secondCategory"
              :list="trees"
            ></selectCascader>
          </el-form-item>
          <el-form-item prop="productNumber" label="商品编号" verify>
            <!-- :productNumber.sync="formData.productNumber" -->
            <selected
              v-model="formData.productNumber"
              :productId.sync="formData.productId"
            />
          </el-form-item>
          <el-form-item prop="isPresale" label="是否预售" verify>
            <gl-option
              v-model="formData.isPresale"
              :list="presaleEnum"
            ></gl-option>
          </el-form-item>
          <!-- <el-form-item v-if="formData.isPresale == '1'" prop="presalePrice" label="预售价" verify>
              <el-input v-model="formData.presalePrice"></el-input>
            </el-form-item> -->
          <el-form-item
            v-if="formData.isPresale == '1'"
            prop="presaleDate"
            label="预售日期"
            verify
          >
            <el-date-picker
              v-model="formData.presaleDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="预售日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item prop="isQuantity" label="是否限购" verify>
            <gl-option
              v-model="formData.isQuantity"
              :list="quantityEnum"
            ></gl-option>
          </el-form-item>
          <el-form-item
            v-if="formData.isQuantity == 1"
            prop="quantity"
            label="限购数量"
            verify
          >
            <el-input v-model="formData.quantity"></el-input>
          </el-form-item>
          <el-form-item prop="deliverDate" label="发货时间" verify>
            <!-- <el-date-picker
                v-model="formData.deliverDate"
                type="date"
                value-format="yyyy-MM-dd"  
                placeholder="发货时间">
              </el-date-picker> -->
            <el-row>
              <el-col :span="15">
                <el-input
                  v-model="formData.deliverDate"
                  type="number"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <span style="margin-left: 5px; color: #aaa; font-size: 12px">
                  {{ formData.deliverDate }}天内发货
                </span>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item prop="isAfterSales" label="是否支持7天无理由" verify>
            <gl-option
              v-model="formData.isAfterSales"
              :list="afterSalesEnum"
            ></gl-option>
          </el-form-item>
          <!-- <el-form-item prop="skuPicture" label="商品规格图片" verify>
              <gl-upload-images v-model="formData.skuPicture" :limit="2"></gl-upload-images>
            </el-form-item> -->
          <el-form-item prop="isBatch" label="是否批发" verify>
            <gl-option
              v-model="formData.isBatch"
              :list="afterSalesEnum"
            ></gl-option>
          </el-form-item>
          <el-form-item
            v-if="formData.isBatch == 1"
            prop="batchQuantity"
            label="起批数量"
            verify
          >
            <el-input
              v-model="formData.batchQuantity"
              type="number"
              :maxlength="10"
              style="width: calc(100% - 30px)"
            ></el-input>
            <span style="margin-left: 5px; color: #aaa; font-size: 12px"
              >个</span
            >
          </el-form-item>
        </el-col>
        <el-col :span="22">
          <el-form-item prop="goodsItemPictureVOList" label="商品主图" :watch="formData.goodsItemPictureVOList" verify>
            <!-- <gl-upload-images ref="uploadImage" :autoUpload="false" v-model="formData.goodsItemPictureVOList" :limit="9" :oldList="oldList"></gl-upload-images> -->
            <gl-drag-upload v-model="formData.goodsItemPictureVOList" :oldList="oldList" :limit="9" :autoUpload="false" ref="uploadImage"></gl-drag-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { presaleEnum, quantityEnum, afterSalesEnum } from './enum'
import { getTree } from '@/api/merchant/goods'
import selectCascader from './selectCascader.vue'
import selected from './selected.vue'

export default {
  components: { selectCascader, selected },
  data() {
    return {
      formData: {
        goodsItemPictureVOList: [],
        isBatch: '0',
      },
      oldList: [],
      loading: false,
      trees: [],
      presaleEnum: presaleEnum,
      quantityEnum: quantityEnum,
      afterSalesEnum: afterSalesEnum,
    }
  },
  watch: {
    'formData.isPresale': {
      handler: function (newVal) {
        this.$store.state.goods.isPresale = Number(newVal)
      },
    },
    'formData.isBatch': {
      handler: function (newVal) {
        this.$store.state.goods.isBatch = Number(newVal)
      },
    },
  },
  mounted() {
    this.getTrees()
  },
  methods: {
    async getTrees() {
      const res = await getTree()
      this.trees = res.data
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            const srcs = await this.$refs.uploadImage.upload()
            this.formData.goodsItemPictureVOList = srcs.map((item, index) => {
              return {
                pictureUrl: item,
                sort: index,
                pictureType: 1,
              }
            })
            console.log(this.formData, 'this.formData')
            resolve(this.formData)
          } else {
            reject(valid)
          }
        })
      })
    },
    async validate_old() {
      //已弃用
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          // console.log('formData:', this.formData)
          if (valid) {
            this.formData.goodsItemPictureVOList =
              this.formData.goodsItemPictureVOList.map((item, index) => {
                return {
                  pictureUrl: item,
                  sort: index,
                  pictureType: 1,
                }
              })
            resolve(this.formData)
          } else {
            reject(valid)
          }
        })
      })
    },
    setFormData(formData) {
      this.formData = formData
      formData.goodsItemPictureVOList = formData.goodsItemPictureVOList.map(
        (item) => item.pictureUrl
      )
      this.oldList = [...formData.goodsItemPictureVOList]
    },
  },
}
</script>

<style></style>
