<template>
  <div class="addSkusizes">
    <el-form :model="formData" ref="form" label-position="top" size="mini">
      <el-form-item label="尺码规格">
        <el-row :gutter="24">
          <el-col :span="10">
            <el-input v-model="value"></el-input>
          </el-col>
          <el-col :span="12" style="padding-left:10px;">
            <el-button type="primary" size="mini" @click="handleAddSkSize"> 添加 </el-button>
          </el-col>
          <el-col :span="24">
            <skuSizesItem style="margin-top:10px;" @change="e => $store.commit('goods/setSkuSizes',e)"/>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import skuSizesItem from './skuSizesItem.vue'
export default {
  components: {skuSizesItem},
  data(){
    return{
      formData: {},
      value: '',
    }
  },
  methods: {
    handleAddSkSize(){
      if(!this.value) return
      if(this.$store.state.goods.checkList.includes(this.value)) return this.$message.warning('已添加')
      this.$store.commit('goods/addSkuSizes', this.value)
      this.value = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.addSkusizes{
  .skuSizes{
    padding: 10px 10px 12px;
    border: 1px solid red;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    background: #fcfcfc;
    border: 1px solid #eee;
    border-radius: 9px;

    &_item{
      width: 50px;
      border-radius: 10px;
    }
  }
}
  
</style>