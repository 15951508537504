<template>
  <div class="skuSizes">
    <el-checkbox-group v-model="$store.state.goods.checkList" @change="change">
      <el-checkbox v-for="(item,index) of newList" :key="index" :label="item">
        <span class="item-area">
          <span> {{item}} </span>
          <span @click.prevent>
            <i @click.self="handleRemove(index)" class="icon-delete el-icon-delete"></i>
          </span>
        </span>
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  data(){
    return{
      checkList: ['均码'],
    }
  },
  computed: {
    newList(){
      return this.$store.state.goods.skuSizes
    },
  },
  mounted(){
    
  },
  methods: {
    change(e){
      this.$emit('change', e)
    },
    handleRemove(index){
      this.$confirm(`是否确认删除选项"${this.$store.state.goods.skuSizes[index]}"？`, '尺码', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(res => {
        this.$store.commit('goods/removeSkuSizes', index)
      }).catch(err => {
        
      })
    },
  },
}
</script>

<style lang="scss" scoped>

.skuSizes{
  min-height: 46px;
  border: 1px solid red;
  padding-top:10px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  background: #fcfcfc;
  border: 1px solid #eee;
  padding-bottom: 12px;

  &_item{
    padding: 5px 10px;
    border-radius: 10px;
  }

  .item-area{
    // color: #606266;
    .icon-delete{
      margin-left: 1px;
      color: #606266;
    }
    .icon-delete:hover{
      color: #409EFF;
    }
  }


}

  

</style>