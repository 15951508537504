<template>
  <div class="addSku">
    <el-form :model="formData" ref="form" label-position="top" size="mini">
      <el-form-item label="款式描述">
        <el-row :gutter="24">
          <el-col :span="10" style="padding-bottom: 12px;">
            <el-input v-model="value"></el-input>
          </el-col>
          <el-col :span="12" class="color-box">
            <el-button type="primary" size="mini" @click="handleAddSku"> 添加 </el-button>
          </el-col>
          <!-- <div class="list">
            <div class="list_item">
              <div class="list_item_item"  v-for="(item,index) of list" :key="index">
                {{item}}
              </div>
              <el-button style="margin-left:5px;" type="primary" size="mini" @click="handleAddSku"> 添加 </el-button>
            </div>
          </div> -->
          <el-col :span="24">
            <div class="name_list">
              <!-- <div class="name_list_item"> -->
                <div class="list-item"  v-for="(item,index) of list" :key="index">
                  {{item}}
                  <span @click.prevent>
                    <i @click.self="handleRemove(index)" class="icon-delete el-icon-delete"></i>
                  </span>
                </div>
              <!-- </div> -->
            </div>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data(){
    return{
      formData: {},
      value: ''
    }
  },
  computed: {
    list(){
      return this.$store.state.goods.skuNames
    },
  },
  methods: {
    handleAddSku(){
      if(!this.value) return
      // if(!this.$store.state.goods.checkList.length) return this.$message.warning('请先添加尺码')
      if(this.$store.state.goods.skuNames.includes(this.value)) return this.$message.warning('已添加')
      this.$store.commit('goods/addSkuNames', this.value)
      this.value = ''
    },
    handleRemove(index){
      this.$confirm(`是否确认删除选项"${this.$store.state.goods.skuNames[index]}"？`, '规格', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(res => {
        this.$store.commit('goods/removeSkuNames', index)
      }).catch(err => {
        
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.addSku{
  .color-box{
    // display: flex;
    
    .box-item{
      display: flex;
      padding: 0 5px;
    }
  }
  .list{
    &_item{
      display: inline-block;
      
      &_item{
        display: inline;
        background: #fcfcfc;
        border: 1px solid #eee;
        border-radius: 10px;
        padding: 5px;
        margin: 0 3px;
      }
      &_item:first-child{
        margin-left: 10px;
      }
    }
  }
  .name_list{
    min-height: 36px;
    border: 1px solid #eee;
    background: #fcfcfc;
    border-radius: 10px;
    // padding-top:10px;
    padding-bottom: 12px;
    padding: 10px;
    display: flex;
    &_item{
      min-height: 36px;
      display: flex;
    }
    .list-item~.list-item{
      margin-left: 20px;
      height: 28px;
      line-height: 28px;
    }

    .icon-delete{
      margin-left: 1px;
      color: #606266;
      cursor:pointer
    }
    .icon-delete:hover{
      color: #409EFF;
    }
  }
}

</style>