<template>
  <div>
    <el-table :data="tableData" style="width: 100%" border :span-method="objectSpanMethod">
      <el-table-column prop="time" label="时间"></el-table-column>
      <el-table-column prop="grade" label="年级"></el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column prop="subjects" label="科目"></el-table-column>
      <el-table-column prop="score" label="成绩"></el-table-column>

      <!-- <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" width="44"></el-table-column> -->
      <el-table-column prop="skuSize" label="大小"></el-table-column>
      <el-table-column prop="itemName" label="规格">
        <template slot-scope="{row}">
          <span> {{row.skuSize}} </span>
        </template>
      </el-table-column>
      <el-table-column prop="listtingTime" label="价格"></el-table-column>
      
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'customTable',
  data(){
    return{
      tableData: [
        { time: '2020-08-10', grade: '三年二班', name: '小明', subjects: '语文', score: 80 , skuSize: '均码',},
        { time: '2020-08-10', grade: '三年二班', name: '小明', subjects: '数学', score: 80 , skuSize: '均码',},
        { time: '2020-08-10', grade: '三年一班', name: '小雷', subjects: '语文', score: 70 , skuSize: '大',},
        { time: '2020-08-10', grade: '三年一班', name: '小雷', subjects: '数学', score: 80 },
        { time: '2020-08-11', grade: '三年三班', name: '小花', subjects: '语文', score: 60 }, 
        { time: '2020-08-11', grade: '三年三班', name: '小花', subjects: '数学', score: 60 }, 
      ],
      mergeObj: {},
      mergeArr: ['time', 'grade', 'name', 'subjects', 'score','skuSize']
    }
  },
  computed: {
    tableList(){
      return this.$store.state.goods.tableList
    },
  },
  mounted(){
    this.getSpanArr(this.tableData)
  },
  methods: {
    getSpanArr(data){
      const tableList = this.tableData
      this.mergeArr.forEach((key,index1) => {
        let count = 0  //用来记录需要合并行的起始位置
        this.mergeObj[key] = []  //记录每一列的合并信息
        data.forEach((item,index) => {
          // index == 0 表示数据为第一行, 直接push 一个1
          if(index == 0){
            this.mergeObj[key].push(1)
          }else {
            // 判断当前行是否与上一行其值相等 如果相等 在 count 记录的位置其值 +1 表示当前行需要合并 并push 一个 0 作为占位
            if(item[key] === data[index - 1][key]) { 
                this.mergeObj[key][count] += 1;
                this.mergeObj[key].push(0);
            } else {
                // 如果当前行和上一行其值不相等 
                count = index; // 记录当前位置 
                this.mergeObj[key].push(1); // 重新push 一个 1
            }
          }
        })
        // console.error('mergeArr:', this.mergeArr)
        // console.error('mergeObj:', this.mergeObj)
      })
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }){
      // console.log('row:', row)
      // console.log('column.property:', column)
      // console.log('mergeObj:', this.mergeObj)
      // console.log('rowIndex:', rowIndex)
      //判断列的属性
      if(this.mergeArr.includes(column.property)){
        // 判断其值是不是0
        if(this.mergeObj[column.property][rowIndex]){
          return [this.mergeObj[column.property][rowIndex], 1]
        }else {
          // 如果为0则为需要合并的行
          return [0, 0]; 
        }
      }

    },
  },
}
</script>

<style>

</style>