<template>
  <div class="update-add">
    <gl-title :title="title" backIcon></gl-title>
    <div
      v-loading="loading"
      style="
        margin: 20px;
        padding: 10px;
        background-color: #fff;
        border-radius: 4px;
      "
    >
      <addForm ref="fomrData" />
    </div>
    <gl-card v-loading="loading">
      <!-- 添加尺寸 -->
      <addSkuSizes />
      <!-- 添加规格 -->
      <addSku />
    </gl-card>
    <div
      v-loading="loading"
      style="
        margin: 18px;
        padding: 10px;
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid #ebeef5;
      "
    >
      <!-- 表格 -->
      <!-- <customTable/> -->
      <addTable ref="tableData" />
    </div>
    <gl-card v-loading="loading">
      <el-form :model="formData" ref="form" size="mini" label-position="top">
        <el-form-item prop="itemDetail" label="商品详情描述" verify>
          <gl-editor v-model="formData.itemDetail" :height="500"></gl-editor>
        </el-form-item>
      </el-form>
      <gl-flex>
        <el-button type="primary" size="mini" @click="onSubmit">
          提交
        </el-button>
      </gl-flex>
    </gl-card>
  </div>
</template>

<script>
import addSkuSizes from './components/addSkuSizes.vue'
import addSku from './components/addSku.vue'
import customTable from './components/customTable.vue'
import addTable from './components/addTable.vue'
import addForm from './components/addForm.vue'
import { goodsAdd, getGoods, goodsUpdate } from '@/api/merchant/goods'
export default {
  components: {
    addSkuSizes,
    addSku,
    customTable,
    addTable,
    addForm,
  },
  data() {
    return {
      formData: {
        itemDetail: '',
      },
      loading: false,
    }
  },
  computed: {
    itemId() {
      return this.$route.query.itemId
    },
    title() {
      return this.$route.query.title || '新增'
    },
  },
  mounted() {
    if (this.itemId) {
      this.getGoods()
    }
  },
  methods: {
    async handleVerify() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve(this.formData)
          } else {
            reject(valid)
          }
        })
      })
    },
    async onSubmit() {
      this.loading = true
      try {
        const valid = await this.$refs.tableData.validate()
        const formData = await this.$refs.fomrData.validate()
        const form = await this.handleVerify()

        this.$store.commit('goods/setGoodsSkuVOList')
        let params = {
          ...formData,
          ...form,
          goodsSkuVOList: this.$store.state.goods.goodsSkuVOList,
        }
        if (this.itemId) return this.handleUpdate(params)
        const res = await goodsAdd({ ...params, itemId: this.itemId })
        this.loading = false
        this.$message({ type: 'success', message: '操作成' })
        this.$router.go(-1)
      } catch (e) {
        this.loading = false
        console.error('catch-e:', e)
      }
    },
    async handleUpdate(data) {
      try {
        const res = await goodsUpdate(data)
        this.$message({ type: 'success', message: '操作成' })
        this.$router.go(-1)
      } catch (e) {}
    },
    async getGoods() {
      let params = {
        itemId: this.itemId,
      }
      const res = await getGoods(params)
      this.$store.state.goods.tableForm = res.data
      this.$store.state.goods.goodsSkuVOList = res.data.goodsSkuVOList
      const formData = {
        itemId: res.data.itemId,
        itemName: res.data.itemName, // 商品名称
        productId: res.data.productId, //商品编号id
        productNumber: res.data.productNumber, //商品编号
        factoryId: res.data.factoryId,
        firstCategory: res.data.firstCategory, //商品类目
        secondCategory: res.data.secondCategory, //商品类目
        presaleDate: res.data.presaleDate, // 预售日期
        presalePrice: res.data.presalePrice, // 预售价
        isQuantity:
          res.data.isQuantity != null ? res.data.isQuantity.toString() : '', // 是否限购
        quantity: res.data.quantity,
        isPresale: res.data.isPresale.toString(), //  是否预售
        isAfterSales: res.data.isAfterSales.toString(), // 是否支持7天无理由
        isBatch: res.data.isBatch.toString(), //是否批发
        batchQuantity: res.data.batchQuantity, //起批数量
        itemDetail: res.data.itemDetail, //富文本详情

        deliverDate: res.data.deliverDate, // 发货时间
        goodsItemPictureVOList: res.data.goodsItemPictureVOList,
      }
      this.formData.itemDetail = formData.itemDetail

      this.$refs.fomrData.setFormData(formData)

      const goodsSkuVOList = res.data.goodsSkuVOList

      let skuSizes = []
      let skuNames = []
      goodsSkuVOList.forEach((item) => {
        if (!skuSizes.includes(item.skuSize)) {
          skuSizes.push(item.skuSize)
        }
        if (!skuNames.includes(item.skuName)) {
          skuNames.push(item.skuName)
        }
      })
      this.$store.state.goods.skuSizes = JSON.parse(JSON.stringify(skuSizes))
      this.$store.state.goods.checkList = JSON.parse(JSON.stringify(skuSizes))
      this.$store.state.goods.skuNames = skuNames

      this.$store.state.goods.tableList = goodsSkuVOList.map((item) => item)

      this.$store.state.goods.tableForm = {
        presalePrice: goodsSkuVOList[0].presalePrice,
        retailPrice: goodsSkuVOList[0].retailPrice,
        inventory: goodsSkuVOList[0].inventory,
        costPrice: goodsSkuVOList[0].costPrice,
        batchPrice: goodsSkuVOList[0].batchPrice,
      }
      //一下代码为vuex-goods-mutations 完美解决添加尺寸与规格动态问题
      //保存图片路径
      let goodsImage = {}
      goodsSkuVOList.forEach((item) => {
        if (
          goodsImage[item.skuSize] == undefined ||
          !goodsImage[item.skuSize]
        ) {
          goodsImage[item.skuSize] = {}
        }
        goodsImage[item.skuSize][item.skuName] = item.skuPicture
      })
      this.$store.state.goods.goodsImage = goodsImage
      //保存tableData
      this.$store.state.goods.tableData = skuSizes.map((item) => {
        return {
          skuSize: item,
        }
      })
    },
  },
  destroyed() {
    this.$store.commit('goods/clear', '')
  },
}
</script>

<style lang="scss"></style>
