<template>
  <div class="uploadBtn">
    <!-- list-type="picture" -->
    <el-upload
      class="upload-demo"
      action="https://jsonplaceholder.typicode.com/posts/"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :http-request="httpRequest"
      :file-list="fileList"
      >
      <el-button size="mini" type="text">点击上传</el-button>
    </el-upload>
    <el-image
      v-if="value"
      style="width:50px;height:50px;margin-left:5px;"
      :src="this.value"
      fit="cover"
      :preview-src-list="[this.value]"
    />
    <!-- <el-button style="margin-left:10px;" size="mini" type="text" @click="handlePreview2">预览</el-button>
    <el-dialog :visible.sync="dialogVisible" :modal="false">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog> -->
  </div>
</template>

<script>
import {upload} from '@/api/user'
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: Number,
      default: 1
    },
    index: {
      type: Number,
      default: null,
    },
    rows:{
      type: Object,
      default: () => {},
    }
  },
  name: 'uploadBtn',
  data(){
    return{
      fileList: [],
      urlList: [],

      dialogVisible: false,
      dialogImageUrl: '',
    }
  },
  methods: {
    async httpRequest(e){
      // return this.$refs.upload.submit()
      const file = e.file
      const form = new FormData()
      form.append('file', file)
      form.append('type', this.type)
      upload(form).then(res => {
        if(res.code == 200){
          this.$store.state.goods.goodsImage[this.rows.skuSize][this.rows.skuName] = res.data

          this.urlList.push(res.data)
          const value = res.data
          this.$emit('input', value)
          e.onSuccess(res)
        }else{
          e.onError(res)
        }
      })
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handlePreview2(){
      if(!this.value) return this.$message({type: 'warning', message: '请上传图片'})
      this.dialogImageUrl = this.value
      this.dialogVisible = true
    },
  },
}
</script>

<style lang="scss" scoped>
.uploadBtn{
  display: flex;
  align-items: center;
}
</style>

<style lang="scss">
.uploadBtn{
  .el-upload-list{
    display: none;
  }
}
</style>