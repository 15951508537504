<template>
  <div class="addTable">
    <div class="table-title">销售规格</div>
    <div class="table-top">
      <div class="describe">在标题栏中输入或选择内容可以进行筛选和批量填充</div>
      <el-button type="primary" size="mini" @click="handleBatchAdd"> 批量填充 </el-button>
    </div>
  
    <el-table :data="tableList" style="width: 100%" border :span-method="objectSpanMethod">
      <el-table-column prop="skuSize" label="大小" width="150"></el-table-column>
      <el-table-column prop="skuName" label="规格"></el-table-column>
      <!-- <el-table-column prop="category" label="分类">
        <template slot-scope="{row}">
          <span> {{row.skuType}} </span>
        </template>
      </el-table-column> -->
      <el-table-column prop="retailPrice" label="价格(元)">
        <template #header>
          <div class="mytable-header">
            <span>价格</span>
            <span>
              <el-input v-model="$store.state.goods.tableForm.retailPrice" placeholder="价格(元)" size="mini"></el-input>
            </span>
          </div>
        </template>
        <template slot-scope="{row}">
          <el-input 
            v-model="row.retailPrice" 
            placeholder="价格" size="mini" >
          </el-input>
          <!-- <el-input 
            v-model="row.retailPrice" 
            placeholder="价格" size="mini" 
            oninput="value = value.replace(/[^\.\d]/g, '')"
            @blur="()=>{row.retailPrice=keepTwoDecimalFull(row.retailPrice)}">
          </el-input> -->
        </template>
      </el-table-column>
      <el-table-column prop="inventory" label="库存(件)">
        <template #header>
          <div class="mytable-header">
            <span>库存</span>
            <span>
              <el-input v-model="$store.state.goods.tableForm.inventory" placeholder="库存(件)" size="mini"></el-input>
            </span>
          </div>
        </template>
        <template slot-scope="{row}">
          <el-input 
            v-model="row.inventory" 
            placeholder="库存" size="mini">
          </el-input>
        </template>
      </el-table-column>
      <el-table-column prop="costPrice" label="出厂价(元)">
        <template #header>
          <div class="mytable-header">
            <span>出厂价</span>
            <span>
              <el-input v-model="$store.state.goods.tableForm.costPrice" placeholder="出厂价(元)" size="mini"></el-input>
            </span>
          </div>
        </template>
        <template slot-scope="{row}">
          <el-input 
            v-model="row.costPrice" 
            placeholder="出厂价" size="mini">
          </el-input>
        </template>
      </el-table-column>
      <el-table-column prop="presalePrice" label="预售价" v-if="$store.state.goods.isPresale">
        <template #header>
          <div class="mytable-header">
            <span>预售价</span>
            <span>
              <el-input v-model="$store.state.goods.tableForm.presalePrice" placeholder="预售价(元)" size="mini"></el-input>
            </span>
          </div>
        </template>
        <template slot-scope="{row}">
          <el-input v-model="row.presalePrice" placeholder="预售价" size="mini"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="presalePrice" label="预售价" v-if="$store.state.goods.isBatch">
        <template #header>
          <div class="mytable-header">
            <span>批发价</span>
            <span>
              <el-input v-model="$store.state.goods.tableForm.batchPrice" placeholder="批发价(元)" size="mini"></el-input>
            </span>
          </div>
        </template>
        <template slot-scope="{row}">
          <el-input v-model="row.batchPrice" placeholder="批发价" size="mini"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="skuPicture" label="上传规格图片">
        <template slot-scope="{row}">
          <uploadBtn :index="row.index" :rows="row" v-model="row.skuPicture" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import uploadBtn from './uploadBtn.vue'
export default {
  components: {
    uploadBtn,
  },
  data(){
    return {
      formData: {
        skuSize: null, //尺寸
        skuName: null,  //规格名称
        inventory: null,  // 库存
        retailPrice: null, //零售单价
        costPrice: null,  // 出厂价
        skuPicture: null, // 商品规格图片
      },
      tableData: [
        {skuSize: '均码', skuType: '规格1', price: 10, num: 5},
        {skuSize: '均码', skuType: '规格2', price: 20, num: 5},
        {skuSize: '均码', skuType: '规格3', price: 30, num: 5},
        {skuSize: '大', skuType: '规格1', price: 10, num: 5},
        {skuSize: '大', skuType: '规格2', price: 20, num: 5},
        {skuSize: '中', skuType: '规格2', price: 20, num: 5},
        {skuSize: '均码', skuType: '规格1', price: 10, num: 10},
      ],
      mergeObj: {},
      mergeArr: ['skuSize'],  // 'price'
      isPresale: 0, // 是否预售  1:是  0:否
    }
  },
  computed: {
    tableList(){
      return this.$store.state.goods.tableList
    },
  },
  watch: {
    tableList:{
      handler: function(newVal){
        console.log('tableList:', newVal)
        this.getSpanArr(newVal)
      }
    },
    '$store.state.goods.isPresale': {
      handler: function(newVal){
        this.isPresale = newVal
      }
    },
  },
  mounted(){
    // this.getSpanArr(this.tableData)
  },
  methods: {
    keepTwoDecimalFull(num) {
      var result = parseFloat(num)
      if (isNaN(result)) {
        return ''
      }
      // result = Math.round(num * 100) / 100
      result = Math.floor(num * 100) / 100
      var s_x = result.toString()
      var pos_decimal = s_x.indexOf('.')
      if (pos_decimal < 0) {
        pos_decimal = s_x.length
        s_x += '.'
      }
      while (s_x.length <= pos_decimal + 2) {
        s_x += '0'
      }
      return  s_x
    },
    // inputChange(e,row){
    //   const str = e.split('.')[1]
    //   if(str.length >= 2) row.retailPrice = Math.floor(Number(e) * 100) /100
    // },
    async validate(){
      /** 
      console.log('submit-tableList:', this.$store.state.goods.tableList)
      if(!this.$store.state.goods.tableForm.retailPrice){
        this.$message({type: 'warning', message: '请输入规格价格'})
        return Promise.reject({message: '请输入规格价格'})
      }else if(!this.$store.state.goods.tableForm.inventory){
        this.$message({type: 'warning', message: '请输入库存数量'})
        return Promise.reject({message: '请输入库存数量'})
      }else if(!this.$store.state.goods.tableForm.costPrice){
        this.$message({type: 'warning', message: '请输入规格出厂价格'})
        return Promise.reject({message: '请输入规格出厂价格'})
      }else if(!this.$store.state.goods.tableForm.presalePrice){
        if(this.isPresale == 1){
          this.$message({type: 'warning', message: '请输入预售价格'})
          return Promise.reject({message: '请输入预售价格'})
        }
      }
      */
      const tableList = this.$store.state.goods.tableList
      
      for(let i=0; i < tableList.length; i++){
        if(!tableList[i].skuPicture){   // 规格图片
          this.$message({type: 'warning', message: `请上传${tableList[i].skuSize} 项 ${tableList[i].skuName} 图片`})
          return Promise.reject({message: `请上传${tableList[i].skuSize} 项 ${tableList[i].skuName} 图片`})
          break
        }
        else 
        if(!tableList[i].retailPrice){  // 价格
          this.$message({type: 'warning', message: `请输入${tableList[i].skuSize} 项 ${tableList[i].skuName} 价格`})
          return Promise.reject({message: `请输入${tableList[i].skuSize} 项 ${tableList[i].skuName} 价格`})
        }
        else if(!tableList[i].inventory){  // 库存
          this.$message({type: 'warning', message: `请输入${tableList[i].skuSize} 项 ${tableList[i].skuName} 库存`})
          return Promise.reject({message: `请输入${tableList[i].skuSize} 项 ${tableList[i].skuName} 库存`})
        }
        else if(!tableList[i].costPrice){  // 出厂价
          this.$message({type: 'warning', message: `请输入${tableList[i].skuSize} 项 ${tableList[i].skuName} 出厂价`})
          return Promise.reject({message: `请输入${tableList[i].skuSize} 项 ${tableList[i].skuName} 出厂价`})
        }
        else if(!tableList[i].presalePrice){  // 预售价
          if(this.isPresale == 1){
            this.$message({type: 'warning', message: `请输入${tableList[i].skuSize} 项 ${tableList[i].skuName} 预售价`})
            return Promise.reject({message: `请输入${tableList[i].skuSize} 项 ${tableList[i].skuName} 预售价`})
          }
        }
        
        if(!tableList[i].batchPrice && this.$store.state.goods.isBatch == 1){
          this.$message({type: 'warning', message: `请输入${tableList[i].skuSize} 项 ${tableList[i].skuName} 批发价`})
          return Promise.reject({message: `请输入${tableList[i].skuSize} 项 ${tableList[i].skuName} 批发价`})
        }
      }
      return true
    },
    handleBatchAdd(){
      this.$store.commit('goods/setTableList', this.$store.state.goods.tableForm)
    },
    getSpanArr(data){
      const tableList = this.tableData
      this.mergeArr.forEach((key,index1) => {
        let count = 0  //用来记录需要合并行的起始位置
        this.mergeObj[key] = []  //记录每一列的合并信息
        data.forEach((item,index) => {
          // index == 0 表示数据为第一行, 直接push 一个1
          if(index == 0){
            this.mergeObj[key].push(1)
          }else {
            // 判断当前行是否与上一行其值相等 如果相等 在 count 记录的位置其值 +1 表示当前行需要合并 并push 一个 0 作为占位
            if(item[key] === data[index - 1][key]) { 
                this.mergeObj[key][count] += 1;
                this.mergeObj[key].push(0);
            } else {
                // 如果当前行和上一行其值不相等 
                count = index; // 记录当前位置 
                this.mergeObj[key].push(1); // 重新push 一个 1
            }
          }
        })
      })
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }){
      //判断列的属性
      if(this.mergeArr.includes(column.property)){
        // 判断其值是不是0
        if(this.mergeObj[column.property][rowIndex]){
          return [this.mergeObj[column.property][rowIndex], 1]
        }else {
          // 如果为0则为需要合并的行
          return [0, 0]; 
        }
      }

    },
  },
}
</script>

<style lang="scss" scoped>
.addTable{
  .table-title{
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  .table-top{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .describe{
      font-size: 12px;
      color: #666;
      display: inline-block;
      line-height: 28px;
    }
  }
}
  
</style>

<style lang="scss">
.addTable{
  .mytable-header{
    display: flex;
    align-content: center;
    //选项组件覆盖
    .el-input__inner{
      padding: 0 3px !important;
      min-width: 50px !important;
      max-width: 100px !important;
      color: #909399;

      // border-color: #c0c4cc;
      // background-color: #e8e8e8;
    }
    .el-input__inner:focus{
      border-color:#DCDFE6;
    }
    .el-input {
        position: relative;
        font-size: 14px;
        display: inline-block;
        /* width: 100%; */
    }
  }

  .el-table .el-table__header th .cell {
    height: 28px !important;
    line-height: 28px !important;
  } 
  .el-table{
    &.el-table--fit {
      border-top: 1px solid #EBEEF5;
      border-left: 1px solid #EBEEF5
    }
  }
}

</style>